import { PageTemplate } from './PageTemplate' 
import { CandidateJobComponent} from '../components/Candidate/CandidateJobComponent'
const ShowCandidateMyJobsPage = ()=> {
    return (
        <PageTemplate 
            type="candidate" headerPage="quicklauncher" headerElement="jobs"
            leftPaneComponent={null} mainComponent={CandidateJobComponent()} buttonComponent={null} >
        </PageTemplate>
    );
}
export { ShowCandidateMyJobsPage }